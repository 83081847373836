var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "get-more-points" },
    [
      _c("h4", { staticClass: "text-uppercase" }, [
        _vm._v(_vm._s(_vm.$t("getMorePoints.title")))
      ]),
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.saveData.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "d-flex align-center" }, [
            _c("div", [
              _c("img", {
                attrs: {
                  src: "/img_layout/points/stella-20.png",
                  alt: "stella-20"
                }
              })
            ]),
            _c(
              "div",
              { staticClass: "flex-grow-1 pl-2" },
              [
                _c("v-text-field", {
                  attrs: {
                    color: "primary",
                    label: _vm.$t("getMorePoints.phone"),
                    dense: "",
                    outlined: "",
                    rounded: "",
                    "hide-details": "auto"
                  },
                  model: {
                    value: _vm.userData.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.userData, "phone", $$v)
                    },
                    expression: "userData.phone"
                  }
                })
              ],
              1
            )
          ]),
          _c("v-divider", { staticClass: "my-4" }),
          _c(
            "div",
            [
              _c("div", { staticClass: "text-body-0 font-weight-semibold" }, [
                _vm._v(" " + _vm._s(_vm.$t("getMorePoints.online")) + " ")
              ]),
              _c(
                "v-radio-group",
                {
                  model: {
                    value: _vm.userData.onlineShopping,
                    callback: function($$v) {
                      _vm.$set(_vm.userData, "onlineShopping", $$v)
                    },
                    expression: "userData.onlineShopping"
                  }
                },
                _vm._l(_vm.onlineShoppingOptions, function(n, i) {
                  return _c("v-radio", {
                    key: i,
                    attrs: { label: n.label, value: n.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("v-divider", { staticClass: "my-4" }),
          _c("div", { staticClass: "d-flex align-center" }, [
            _c("div", [
              _c("img", {
                attrs: {
                  src: "/img_layout/points/stella-20.png",
                  alt: "stella-20"
                }
              })
            ]),
            _c(
              "div",
              { staticClass: "flex-grow-1 px-2 font-weight-semibold" },
              [
                _vm._v(" " + _vm._s(_vm.$t("getMorePoints.familyMembers"))),
                _c("br"),
                _c("span", { staticClass: "font-weight-regular" }, [
                  _vm._v(
                    "(" + _vm._s(_vm.$t("getMorePoints.excludingYou")) + ")"
                  )
                ])
              ]
            ),
            _c(
              "div",
              [
                _c("v-select", {
                  staticClass: "custom-select",
                  attrs: {
                    items: _vm.numbers,
                    dense: "",
                    outlined: "",
                    rounded: "",
                    "hide-details": "auto",
                    width: "100px"
                  },
                  model: {
                    value: _vm.userData.familyMembers,
                    callback: function($$v) {
                      _vm.$set(_vm.userData, "familyMembers", $$v)
                    },
                    expression: "userData.familyMembers"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "mt-6" },
            [
              _c("div", { staticClass: "text-body-0 font-weight-semibold" }, [
                _vm._v(" " + _vm._s(_vm.$t("getMorePoints.online")) + " ")
              ]),
              _c(
                "v-radio-group",
                {
                  model: {
                    value: _vm.userData.pets,
                    callback: function($$v) {
                      _vm.$set(_vm.userData, "pets", $$v)
                    },
                    expression: "userData.pets"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: { label: _vm.$t("common.yes"), value: true }
                  }),
                  _c("v-radio", {
                    attrs: { label: _vm.$t("common.no"), value: false }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    type: "submit",
                    color: "primary",
                    large: "",
                    block: "",
                    depressed: "",
                    rounded: ""
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.save")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }